import { graphql } from "gatsby"
import { getImage, GatsbyImage } from "gatsby-plugin-image"
import React from "react"
import Layout from "../components/layout"
import Seo from "../components/seo"

export default function SinglePostPage({ data }) {
  const page = data.page
  const img = getImage(page.frontmatter?.featured_image)
  return (
    <Layout>
      <Seo title={page.frontmatter.post_headline} />
      <div className="container blog-page">
        <div className="row">
          <div className="col-md-10">
            <h1 className="pt-3">{page.frontmatter.post_headline}</h1>
            <p>{page.frontmatter.post_subtitle}</p>
          </div>
        </div>
        <div className="row">
          <div className="col-md-10">
            {img && (
              <GatsbyImage image={img} alt={page.frontmatter.post_headline} />
            )}
          </div>
        </div>
        <div className="row py-3">
          <div className="col-md-10">
            {page.frontmatter.post_tags.map(tag => (
              <span className="post-tag">{tag}</span>
            ))}
          </div>
        </div>
        <div className="row">
          <div
            className="readingContent col-md-8"
            dangerouslySetInnerHTML={{ __html: page.html }}
          />
        </div>
      </div>
    </Layout>
  )
}

//need dynamic query based on slug based in via context in gatsbynode
export const query = graphql`
  query ($slug: String!) {
    page: markdownRemark(frontmatter: { slug: { eq: $slug } }) {
      frontmatter {
        post_headline
        post_subtitle
        post_tags
        feat_image_toggle
        featured_image {
          childImageSharp {
            gatsbyImageData(layout: FULL_WIDTH)
          }
        }
      }
      html
    }
  }
`
